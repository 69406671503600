.faq-accordion .accordion-item {
  border: none;
}

.faq-accordion .header button {
  background-color: var(--light-gray) !important;
  padding-bottom: 0;
}

.faq-accordion .header button:focus {
  border: none !important;
  box-shadow: none !important;
}

.faq-accordion .header button:not(.collapsed) {
  box-shadow: none !important;
  color: initial !important;
}
