.bg-quiz {
  /* background-color: #aabfff; */
  background-color: #86a4ff;
  color: white;
}
.bg-quiz span:nth-child(2) p p span:nth-child(1) {
  color: white !important;
}

.buttons-quiz:nth-child(2):hover {
  background-color: rgb(4, 131, 249) !important;
  color: #fff !important;
}

.buttons-quiz:nth-child(3):hover {
  background-color: rgb(25, 135, 84) !important;
  color: #fff !important;
}

.buttons-quiz:hover .svg-button path {
  stroke: #fff !important;
}
.incorrect{
  background-color: #ff00008b !important;
  color: #000000 !important;
}
.correct{
  background-color: #00ff3c73 !important;
  color: #000000 !important;
}

/* .bg-correct {
  background: #28a745;
  border-color: none !important;
}

.bg-correct span div p {
  color: #fff;
}

.bg-incorrect span div p {
  color: #ed0000;
}

.bg-incorrect {
  background: #fff; 
  border: 1px solid #ed0000; 
} */

.bg-correct {
  background: #03c985;
  border-radius: 50px;
  min-width: 15px;
  height: 15px;
}

.bg-incorrect {
  background: #ed0942;
  border-radius: 50px;
  min-width: 15px;
  height: 15px;
}

.display-quiz {
  display: block;
}

.hide-quiz {
  display: none;
}

.accordion-main button {
  background: none !important;
}

.accordion-main button h5 {
  color: white;
  font-size: 1rem;
}

.accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed)::after {
  color: #fff !important;
}

.dangerous-text span {
  font-family: unset !important;
}

.dangerous-text p {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.MsoNormal {
  /* text-align: center !important; */
  margin-left: unset !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  text-indent: 0px !important;
}

.MsoListParagraph {
  margin-left: unset !important;
}

.Quiz_quizOption__T5JKy span {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
}

.p-60 {
  padding-left: 60px;
}
.pr-four {
  padding-right: 5px;
}
.border-grey {
  border: 2px solid #f2f2f2;
}
.quiz-wrapper {
  padding: 30px 30px;
  border-radius: 10px;
  border: 1px solid #ccc;
}
.quiz-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 768px) {
  .quiz-header {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }
}
.answers ul {
  margin: 0px;
  padding: 0px;
}
.answers ul li {
  list-style: none;
  line-height: 36px;
  flex-basis: 50%;
}
@media (max-width: 992px) {
  .answers ul li {
    flex-basis: 100%;
    line-height: 28px;
  }
}
/* .answers ul li::before {
  content: "\f111";
  font-family: "FontAwesome";
  font-size: 18px;
  color: #ed0942;
  padding-right: 10px;
} */
/* .color-green::before {
  color: #03c985 !important;
} */
.line {
  color: #727272;
  font-weight: 500;
  max-width: 100%;
  position: relative;
}
.line::before {
  content: "";
  display: block;
  width: 50px;
  height: 2px;
  background: #f2f2f2;
  top: 50%;
  position: absolute;
}
.line::after {
  content: "";
  display: block;
  width: 82%;
  height: 2px;
  background: #f2f2f2;
  right: 0px;
  top: 50%;
  position: absolute;
}
.answer-result .MsoNormal {
  font-weight: unset !important;
}

.answer-result p span {
  font-family: unset !important;
  font-size: unset !important;
}

.question-text p span {
  font-size: 18px !important;
  font-family: unset !important;
}

@media (max-width: 1399.98px) {
  .line::after {
    width: 80%;
  }
}
@media (max-width: 1199.98px) {
  .line::after {
    width: 75%;
  }
}
@media (max-width: 991.98px) {
  .line::before,
  .line::after {
    display: none;
  }
  .p-60 {
    padding-left: 0px;
  }
}

.dangerous-text p{
  margin-bottom: 0px !important;
}