.slick-slide {
  padding-right: 10px;
}
.slick-prev {
  left: -45px !important;
}
.slick-next {
  right: -20px !important;
}
@media (max-width: 576px) {
  .slick-prev {
    display: none !important;
  }
  .slick-next {
    display: none !important;
  }
}