:root {
  --light-gray: rgba(247, 248, 249, 1);
  --dusk-gray: rgba(136, 150, 171, 1);
}

.bg-light-light {
  background-color: var(--light-gray) !important;
}

.text-dusk {
  color: var(--dusk-gray) !important;
}

.text-dusk-hover {
  color: var(--dusk-gray) !important;
  transition: all 0.4s ease;
}

.text-dusk-hover:hover {
  color: gray !important;
}

.fw-semi-semibold {
  font-weight: 500 !important;
}

.text-larger {
  font-size: calc(1.525rem + 2.1vw);
  font-weight: 700;
  letter-spacing: 1.25;
}

.text-large {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 700;
  letter-spacing: 1.25;
}

.section-subheading {
  font-size: 1.25rem;
  font-weight: 500;
}

@media (min-width: 1200px) {
  .text-large {
    font-size: 3rem;
  }

  .text-larger {
    font-size: 3.75rem;
  }
}

@media (min-width: 768px) {
  .mw-md-5xl {
    max-width: 64rem;
  }
}
/* .glass_effect{

background: rgba(0, 0, 0, 0.267);
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(20px);
-webkit-backdrop-filter: blur(20px);
border: 1px solid rgba(0, 0, 0, 1);
} */
.glass_effect {
  width: 95%;
  position: relative;
  padding: 10px;
  background: radial-gradient(
      178.94% 106.41% at 26.42% 106.41%,
      #d9d5d5 0%,
      rgba(255, 255, 255, 0) 71.88%
    ),
    #ffffff;
  box-shadow: 0px 155px 62px rgba(255, 255, 255, 0.37),
    0px 87px 52px rgba(0, 0, 0, 0.05), 0px 39px 39px rgba(0, 0, 0, 0.09),
    0px 10px 21px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: all 0.8s cubic-bezier(0.15, 0.83, 0.66, 1);
  cursor: progress;
}
.glass_effect:hover {
  scale: 1.1;
}

.radio-inputs {
  display: flex;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-inputs > * {
  flex: 0 0 calc(50% - 10px);
  box-sizing: border-box;
}

.radio-input:checked + .radio-tile {
  border-color: #1ca500;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #454545;
  background-color: #c7fcbc;
}

.radio-input:checked + .radio-tile:before {
  transform: scale(1);
  opacity: 1;
  background-color: #1ca500;
  border-color: #1ca500;
}

.radio-input:checked + .radio-tile .radio-icon svg {
  fill: #1ca500;
}

.radio-input:checked + .radio-tile .radio-label {
  color: #093500f6;
}

.radio-input:focus + .radio-tile {
  border-color: #1ca500;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #b5fcb6;
}

.radio-input:focus + .radio-tile:before {
  transform: scale(1);
  opacity: 1;
}

.radio-tile {
  display: flex;
  padding: 0px 3px;
  border-radius: 0.5rem;
  border: 2px solid #b5bfd9;
  background-color: #ffffff80;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
  width: 100%;
  max-width: 100%;
}

.radio-tile:before {
  content: "";
  position: absolute;
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  top: 0.8rem;
  border: 2px solid #b5bfd9;
  background-color: #fff;
  border-radius: 50%;
  right: 0.25rem;
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;
}

.radio-tile:hover {
  border-color: #1ca500;
}

.radio-tile:hover:before {
  transform: scale(1);
  opacity: 1;
}

.radio-icon svg {
  width: 2rem;
  height: 2rem;
  fill: #494949;
}

.radio-label {
  padding: 10px 20px 10px 10px;
  width: 100%;
  display: flex;
  align-items: start;
  gap: 4px;
  color: #707070;
  transition: 0.375s ease;
  font-size: 13px;
}
.radio-input:checked + .radio-tile .radio-label .number {
  background-color: #1ca500 !important;
}
.number {
  font-size: 12px;
  background-color: #2260ff;
  color: #ffffff;
  font-weight: 600;
  height: 20px;
  min-width: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
}

.radio-input {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  height: 0px !important;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 0px !important;
}
.radio-label p {
  font-size: 18px !important;
  margin-bottom: 0px !important;
}

.question-card {
  background-color: beige;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 20px;
  padding-bottom: 15px;
}
.MsoListParagraph {
  text-indent: 0pt !important;
  margin-left: 0px;
}
.MsoListParagraph p {
  font-size: 18px !important;
}
.MathJax {
  font-size: 20px !important;
}
.MsoNormal span {
  font-size: 16px !important;
}
.dangerous-text p {
  font-size: 16px !important;
}

.radio-inputs2 {
  display: flex;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-inputs2 > * {
  flex: 0 0 calc(50% - 10px);
  box-sizing: border-box;
}

.radio-input2:checked + .radio-tile2 {
  border-color: #a58100;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #454545;
  background-color: #f4fcbcce;
}

.radio-input2:checked + .radio-tile2:before {
  transform: scale(1);
  opacity: 0.5;
  background-color: #9fa500;
  border-color: #9fa500;
}

.radio-input2:checked + .radio-tile2 .radio-icon2 svg {
  fill: #9fa500;
}

.radio-input2:checked + .radio-tile2 .radio-label2 {
  color: #093500f6;
}

.radio-input2:focus + .radio-tile2 {
  border-color: #9fa500;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #fcfcb5;
}

.radio-input2:focus + .radio-tile2:before {
  transform: scale(1);
  opacity: 1;
}

.radio-tile2 {
  display: flex;
  padding: 0px 3px;
  border-radius: 0.5rem;
  border: 2px solid #b5bfd9;
  background-color: #ffffff80;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
  width: 100%;
  max-width: 100%;
}

.radio-tile2:before {
  content: "";
  position: absolute;
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  top: 0.8rem;
  border: 2px solid #b5bfd9;
  background-color: #fff;
  border-radius: 50%;
  right: 0.25rem;
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;
}

.radio-tile2:hover {
  border-color: #9fa500;
}

.radio-tile2:hover:before {
  transform: scale(1);
  opacity: 1;
}

.radio-icon2 svg {
  width: 2rem;
  height: 2rem;
  fill: #494949;
}

.radio-label2 {
  padding: 10px 20px 10px 10px;
  width: 100%;
  display: flex;
  align-items: start;
  gap: 4px;
  color: #707070;
  transition: 0.375s ease;
  font-size: 13px;
}
.radio-input2:checked + .radio-tile2 .radio-label2 .number2 {
  background-color: #9fa500 !important;
}
.number2 {
  font-size: 12px;
  background-color: #2260ff;
  color: #ffffff;
  font-weight: 600;
  height: 20px !important;
  min-width: 20px !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1 !important;
}

.radio-input2 {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  height: 0px !important;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 0px !important;
}
.radio-label2 p {
  font-size: 16px !important;
  margin-bottom: 0px !important;
}

/* .katex-html{
  display: none;
} */
.MsoNormal span {
  font-size: 16px !important;
}

.MsoListParagraphCxSpFirst span {
  font-size: 16px !important;
}
.radio-label p,
.radio-label2 p,
.radio-label div p span,
.radio-label2 div p span {
  font-size: 16px;
}
.radio-label {
  font-family: "times new roman", times, serif;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 45px;
  aspect-ratio: 1;
  --c: no-repeat linear-gradient(#000 0 0);
  background: var(--c) 0% 100%, var(--c) 50% 100%, var(--c) 100% 100%;
  animation: l2 1s infinite linear;
}

@keyframes l2 {
  0% {
    background-size: 20% 100%, 20% 100%, 20% 100%;
  }

  20% {
    background-size: 20% 60%, 20% 100%, 20% 100%;
  }

  40% {
    background-size: 20% 80%, 20% 60%, 20% 100%;
  }

  60% {
    background-size: 20% 100%, 20% 80%, 20% 60%;
  }

  80% {
    background-size: 20% 100%, 20% 100%, 20% 80%;
  }

  100% {
    background-size: 20% 100%, 20% 100%, 20% 100%;
  }
}

#overallStatus svg {
  width: 45px;
  height: 45px;
  transition: transform 0.5s ease-out;
}

svg #red-exclamation {
  animation: bounce 0.5s ease-out forwards;
  transform-origin: center center;
}

svg #red-exclamation .exclamation {
  opacity: 0;
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  animation: excl 1.5s ease forwards 0.5s;
}

svg #red-exclamation .dot {
  opacity: 0;
  stroke-width: 30;
  stroke-linecap: round;
  animation: dot-fade 0.175s ease forwards 0.7s;
}

@keyframes excl {
  0% {
    stroke-dashoffset: 100;
  }

  10% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    stroke-dashoffset: 0;
  }
}

@keyframes dot-fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(0.6);
  }

  100% {
    opacity: 1;
    transform: scale(0.9);
  }
}

.dummy-positioning {
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
}

.success-icon {
  display: inline-block;
  width: 8em;
  height: 8em;
  font-size: 20px;
  border-radius: 50%;
  border: 4px solid #96df8f;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  transform-origin: center;
  -webkit-animation: showSuccess 180ms ease-in-out;
  animation: showSuccess 180ms ease-in-out;
  transform: scale(1);
}

.success-icon__tip,
.success-icon__long {
  display: block;
  position: absolute;
  height: 4px;
  background-color: #96df8f;
  border-radius: 10px;
}

.success-icon__tip {
  width: 2.4em;
  top: 4.3em;
  left: 1.4em;
  transform: rotate(45deg);
  -webkit-animation: tipInPlace 300ms ease-in-out;
  animation: tipInPlace 300ms ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 180ms;
  animation-delay: 180ms;
  visibility: hidden;
}

.success-icon__long {
  width: 4em;
  transform: rotate(-45deg);
  top: 3.7em;
  left: 2.75em;
  -webkit-animation: longInPlace 140ms ease-in-out;
  animation: longInPlace 140ms ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  visibility: hidden;
  -webkit-animation-delay: 440ms;
  animation-delay: 440ms;
}

@-webkit-keyframes showSuccess {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@keyframes showSuccess {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@-webkit-keyframes tipInPlace {
  from {
    width: 0em;
    top: 0em;
    left: -1.6em;
  }

  to {
    width: 2.4em;
    top: 4.3em;
    left: 1.4em;
    visibility: visible;
  }
}

@keyframes tipInPlace {
  from {
    width: 0em;
    top: 0em;
    left: -1.6em;
  }

  to {
    width: 2.4em;
    top: 4.3em;
    left: 1.4em;
    visibility: visible;
  }
}

@-webkit-keyframes longInPlace {
  from {
    width: 0em;
    top: 5.1em;
    left: 3.2em;
  }

  to {
    width: 4em;
    top: 3.7em;
    left: 2.75em;
    visibility: visible;
  }
}

@keyframes longInPlace {
  from {
    width: 0em;
    top: 5.1em;
    left: 3.2em;
  }

  to {
    width: 4em;
    top: 3.7em;
    left: 2.75em;
    visibility: visible;
  }
}
.quizOptionNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid;
  text-align: center;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  margin-right: 10px;
}
.quizOptionLabel {
  width: 100%;
  display: flex;
  align-items: center;
}
.test-skill-label {
  cursor: pointer;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 0 auto;
  width: 100%;
  margin-top: 5px;
  border-radius: 25px;
}
.test-skill-input:checked ~ .test-skill-label {
  background-color: #0d6efd;
  color: #fff;
}
.quizOptionLabel p {
  margin: 0;
}

.active {
  font-weight: bold;
  color: blue;
}

.nav-item {
  position: relative;
  display: inline-block;
  padding: 0 15px;
}

.nav-title {
  cursor: pointer;
  padding: 10px;
  display: inline-block;
  text-decoration: none;
}

.nav-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 160px; /* Adjust as needed */
}

.nav-dropdown-item {
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  color: black;
}

.nav-dropdown-item:hover {
  background-color: #f1f1f1;
}

.nav-item:hover .nav-dropdown {
  display: block;
}
