.quizOption {
  cursor: pointer;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 0 auto;
  width: 100%;
  margin-top: 5px;
  border-radius: 25px;
  @media screen and (min-width: 992px) {
    // width: 90%;
  }
  .quizOptionNumber {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 1px solid;
    text-align: center;
    height: 20px;
    width: 20px;
    border-radius: 20px;
    margin-right: 10px;
  }
  p {
    margin: 0;
    // font-size: 1.1rem;
    // text-align: center;
  }
  .quizOptionLabel{
    width: 100%;
    display: flex;
    align-items: center;
   
  }
  &.selected {
    background-color: #2c80f6;
    
  }
}
